
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap/scss/bootstrap";

.panel-heading.contains-buttons {
    @include clearfix;
    .panel-title {
        @extend .float-left;
        padding-top:5px;
    }
}

#app > .container > .alert {
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
}

.sidebar, .main {
    width: 100%;
    padding: $spacer;
    .top, .middle {
        display: flex;
        flex-direction: column;
    }
}

.event-header-photo-wrapper {
    .img-wrapper {
        display: flex;
        img {
            margin-right: 10px;
        }
    }
    .button-wrapper {
        button {
            display: block;
        }
    }
}

.panel {
    border-radius: 4px;
    background: #fafafa;
    padding: 12px;
    margin-bottom: 15px;
}

@include media-breakpoint-up(md) {
    .sidebar {
        width: 30%;
        max-width: 300px;
    }
    .main {
        width: auto;
        flex-grow: 1;
    }
}

.row-inactive {
    opacity: 0.6;
}

html, body, #app, .main-container, .main-row {
    min-height: 100%;
}

#app {
    /* display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    min-height: 100vh; */
    .navbar-static-top, .main-container {
        width: 100%;
        flex-grow: 0;
    }
    .main-container {
        /* flex-grow: 1;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: stretch; */
        // height: 0;
        .main-row {
            flex-grow: 1;
        }
    }

    .sidebar {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        .top, .middle, .bottom {
            width: 100%;
        }
        .middle {
            // flex-grow: 1;
        }
    }
}

.file-item {
    display: block;
    &:before {
        content: '';
        display: block;
        padding-bottom: 100%;
    }
    position: relative;
    transition: border 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    backface-visibility: hidden;
    cursor: pointer;
    box-shadow: rgba($gray-400,0.2) 0 2px 10px;
    border: 1px solid $gray-400;
    &:hover {
        border: 1px solid $blue;
    }
    
    .file-lock {
        position: absolute;
        top: 0;
        right: 0;
        height: 24px;
        width: 24px;
        line-height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .file-body {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: $gray-200;
        transition: background 0.2s ease-in-out;
    }
    
    .file-thumb {
        margin-top: auto;
    }
    
    .file-name {
        display: inline;
        // background: rgba(#000, 0.4);
        // color: #fff;
        padding: 2px 6px;
        margin-bottom: auto;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: relative;
    }
    .file-actions {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: linear-gradient(to bottom, rgba(#000, 0), rgba(#000, 0.05));
    }
    
    &.file-item-has-preview {
        .file-name {
            margin-top: auto;
            background: rgba(#000, 0.4);
            color: #fff;
        }
        .file-thumb {
            position: absolute;
            top: 6px;
            left: 6px;
            right: 6px;
            bottom: 6px;
            img {
                width: 100%;
                height: 100%;
                display: block;
            }
        }
        .file-actions {
            background: $gray-200;
            transition: background 0.2s ease-in-out;
        }
    }

    &.selected {
        border: 1px solid $blue;
        box-shadow: rgba($blue, 0.4) 0 0 10px;
        .file-body {
            background: lighten(desaturate($blue, 80%), 30%);
        }
        &.file-item-has-preview {
            .file-actions {
                background: lighten(desaturate($blue, 80%), 30%);
            }
        }
    }
}

.vertical-gutters {
    padding-top: $grid-gutter-width / 2;
    padding-bottom: $grid-gutter-width / 2;
}

@mixin column($width : 24) {
    flex: 0 0 (100% / 24 * $width);
    max-width: (100% / 24 * $width);
}

.col-document {
    padding: $grid-gutter-width / 2;
    @include column(12);
    @media screen and (min-width: 768px) {
        @include column(24);
    }
    @media screen and (min-width: 900px) {
        @include column(12);
    }
    @media screen and (min-width: 1160px) {
        @include column(8);
    }
    @media screen and (min-width: 1440px) {
        @include column(6);
    }
    @media screen and (min-width: 1630px) {
        @include column(4);
    }
    @media screen and (min-width: 2150px) {
        @include column(3);
    }
    @media screen and (min-width: 2800px) {
        @include column(2);
    }
}

.text-color-white {
    color: #fff;
}
a.text-color-white {
    cursor: pointer;
    &:hover {
        color: #eee;
    }
}

.fa {
    font-size: 14px;
}

.edit-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

#documents-grid {
    display: flex;
    flex-grow: 1;
}

.has-footer-selection {
    margin-bottom: 70px;
}

.media-editor-screen {
    display: flex;
    width: 100%;
    flex-direction: column;
    > .row {
        width: 100%;
        margin: 0;
        padding-bottom: 100px;
        > .container-fluid {
            padding: 0;
        }
    }
    .footer-selection {
        margin-top: auto;
        background: $gray-200;
        padding: $spacer;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        .label {
            white-space: nowrap;
        }
    }
}


.event-selector {
    overflow: hidden;
}
    .event-selector-search {
        margin-bottom: $spacer;
    }

    .event-selector-list {
        flex: 1;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

.wysiwyg-wrapper {
    overflow: hidden;
    padding: 0;
    > .mce-panel {
        box-sizing: border-box;
        border: 0;
    }
}